import React from "react";
import PropTypes from "prop-types";

import { Button, Title, Section, Container, Markdown, getProperty } from "@btc/shared";

import * as styles from "./downloads.module.scss";

export const Downloads = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const downloads = getProperty(data, "items") || [];

  return Array.isArray(downloads) && downloads.length > 0 ? (
    <Section color="black" size="md" noPaddingTop={true}>
      <Container>
        <Title wrapper="h2">{title}</Title>
        <Markdown content={content} />
        <div className={styles.downloads}>
          {downloads.map((download) => {
            const url = getProperty(download, "file.publicURL");
            const text = getProperty(download, "text");

            return (
              <Button color="white" key={url} wrapper="a" href={url} className={styles.download}>
                {text} &rarr;
              </Button>
            );
          })}
        </div>
      </Container>
    </Section>
  ) : null;
};

Downloads.defaultProps = {
  data: {},
};

Downloads.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        file: PropTypes.object.isRequired,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};
