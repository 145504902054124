import React, {useMemo, useState, useEffect} from "react";
import PropTypes from "prop-types";

import { getProperty, isObject, Button, Text } from "@btc/shared";

import { useMeta } from "../../hooks";

import * as styles from "./youtube-iframe.module.scss";

const Cookiebot = {
  renew: () => {
    if (
      `Cookiebot` in window &&
      isObject(window.Cookiebot) &&
      `renew` in window.Cookiebot &&
      typeof window.Cookiebot.renew === 'function'
    ) {
      window.Cookiebot.renew();
    }
  },
  isReady: () => `Cookiebot` in window,
};


const REGEX_URL = /(?:youtu.*be.*)\/(?:watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/;
const PLAYER_URL = "https://www.youtube.com/embed";
const PLAYER_SETTINGS = {
  iv_load_policy: "3",
  modestbranding: "1",
  playsinline: "1",
  showinfo: "0",
  mute: "1",
  rel: "0",
};

export const YoutubeIframe = ({ title, url, autoplay }) => {
  const meta = useMeta();
  const [isReady, setIsReady] = useState(false);
  const queryParams = useMemo(
    () => new URLSearchParams({ ...PLAYER_SETTINGS, autoplay }).toString(),
    [autoplay]
  );

  const cookieText = getProperty(meta, "meta.cookies.video_text");
  const cookieButton = getProperty(meta, "meta.cookies.video_button");

  useEffect(() => {
    setIsReady(Cookiebot.isReady());
    const handler = () => setIsReady(true);

    window.addEventListener(`CookiebotOnLoad`, handler);
    return () => {
      window.removeEventListener(`CookiebotOnLoad`, handler);
    };
  }, []);

  const match = url.match(REGEX_URL);
  if (match === null) {
    return null;
  }

  return (
    <div className={styles.wrapper}>
      {isReady ? (
        <div className="cookieconsent-optout-marketing">
          <div className={styles.cookies}>
            <Text wrapper="div">
              {cookieText}
            </Text>
            <Button size="sm" type="invert" color="primary" onClick={() => Cookiebot.renew()}>
              {cookieButton}
            </Button>
          </div>
        </div>
      ) : null}
      <iframe
        title={title}
        allowFullScreen={true}
        className={styles.iframe}
        data-cookieconsent="marketing"
        data-cookieblock-src={`${PLAYER_URL}/${match[1]}?${queryParams}`}
      />
    </div>
  );
};

YoutubeIframe.defaultProps = {
  url: null,
  title: null,
  autoplay: 0,
};

YoutubeIframe.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  autoplay: PropTypes.number,
};
