import React from "react";
import PropTypes from "prop-types";

import { Button, Title, Section, Container, Markdown, getProperty } from "@btc/shared";

import * as styles from "./mediakit.module.scss";

export const Mediakit = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");

  const linkUrl = getProperty(data, "link.url");
  const linkText = getProperty(data, "link.text");

  return linkUrl && linkText ? (
    <Section size="md">
      <Container>
        <Title wrapper="h2">{title}</Title>
        <Markdown content={content} />
        <Button wrapper="a" href={linkUrl} className={styles.link} target="_blank" rel="noreferrer">
          {linkText} &rarr;
        </Button>
      </Container>
    </Section>
  ) : null;
};

Mediakit.defaultProps = {
  data: {},
};

Mediakit.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    link: PropTypes.object.isRequired,
  }),
};
