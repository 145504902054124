import React from "react";
import PropTypes from "prop-types";

import { Title, Section, Container, Markdown, getProperty } from "@btc/shared";

import { YoutubeIframe } from "../common/youtube-iframe";

import * as styles from "./youtube.module.scss";

export const Youtube = ({ data }) => {
  const title = getProperty(data, "title");
  const content = getProperty(data, "content");
  const items = getProperty(data, "items") || [];

  return Array.isArray(items) && items.length > 0 ? (
    <Section color="black" size="md">
      <Container>
        <Title wrapper="h2">{title}</Title>
        <Markdown content={content} />
        <div className={styles.items}>
          {items.map((link) => {
            const url = getProperty(link, "url");
            const text = getProperty(link, "text");

            return (
              <div key={url} className={styles.item}>
                <Title wrapper="h3">{text}</Title>
                <YoutubeIframe url={url} title={text} />
              </div>
            );
          })}
        </div>
      </Container>
    </Section>
  ) : null;
};

Youtube.defaultProps = {
  data: {},
};

Youtube.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ).isRequired,
  }),
};
