import React from "react";
import PropTypes from "prop-types";

import { MetaTags, getProperty } from "@btc/shared";

import { useMeta } from "hooks";

export const Meta = ({ data }) => {
  const meta = useMeta();

  const title = getProperty(data, "title");
  const description = getProperty(data, "description");
  const pageLabel = getProperty(meta, "meta.pageLabel");
  const companyName = getProperty(meta, "meta.company");

  return (
    <MetaTags
      title={title}
      description={description}
      companyName={companyName}
      pageLabel={pageLabel}
    />
  );
};

Meta.defaultProps = {
  data: {},
};

Meta.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};
